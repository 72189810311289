import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    contentHeaderWrapper: {
        paddingBottom: theme.spacing(4),
    },
    content: {
        '& .flex-space-between': {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            '& *:first-child': {
                marginRight: theme.spacing(2),
            },
            '& .last-update': {
                ...theme.typography.h3,
                fontWeight: 300,
                display: 'inline-block',
            },
        },
    },
}));

import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    heading1: {
        position: 'fixed',
        left: 9999,
        top: 9999,
    },
    contentHeaderWrapper: {
        background: '#e7effd',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    infoWrapper: {
        boxSizing: 'border-box',
    },
    description: {
        marginTop: theme.spacing(1),
    },
    illustrationWrapper: {
        flexGrow: 1,
    },
    pageContentWrapper: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    noMargin: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    avatarImage: {
        width: 100,
        height: 100,
    },
}));

import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';

import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import useStyles from './styles';

export const pageQuery = graphql`
    query legalIndexPage($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                title
            }
            html
        }
    }
`;

const LegalIndexPage = (props) => {
    const {
        location,
        data: { markdownRemark: entry },
    } = props;

    const classes = useStyles();

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title={entry.frontmatter.title}
            classes={{ contentHeaderWrapper: classes.contentHeaderWrapper }}
        >
            {/* eslint-disable-next-line react/no-danger */}
            <article dangerouslySetInnerHTML={{ __html: entry.html }} className={classes.content} />
        </BigContentHeaderLayout>
    );
};

LegalIndexPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
            }),
            html: PropTypes.string,
        }),
    }).isRequired,
};

export default LegalIndexPage;

import React from 'react';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { Avatar, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import PageHeader from '$landing-page/components/page-header';
import PageSection from '$landing-page/components/page-section';
import PageWrapper from '$landing-page/components/page-wrapper';

import useStyles from './styles';

const BigContentHeaderLayout = (props) => {
    const {
        children,
        pathname,
        title,
        description,
        illustrationImage,
        showIllustrationImageOnly,
        avatarImage,
        metaImageSrc,
        content,
    } = props;

    const classes = useStyles(props);
    const theme = useTheme();
    const isScreenSizeMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <PageWrapper pageMetaData={{ title, description, pathname, imageSrc: metaImageSrc }}>
            <PageHeader />
            <Typography variant="h1" className={classes.heading1}>
                {title}
            </Typography>
            <PageSection className={classes.contentHeaderWrapper}>
                {content || (
                    <Grid container alignItems="center">
                        {(isScreenSizeMd || showIllustrationImageOnly) && illustrationImage && (
                            <Grid item className={classes.illustrationWrapper}>
                                <GatsbyImage
                                    image={illustrationImage.childImageSharp.gatsbyImageData}
                                    alt={title}
                                    loading="eager"
                                />
                            </Grid>
                        )}
                        {!showIllustrationImageOnly && (
                            <>
                                <Grid
                                    item
                                    container
                                    className={classes.infoWrapper}
                                    xs={12}
                                    wrap={isScreenSizeMd ? 'nowrap' : 'wrap'}
                                    justify={isScreenSizeMd ? 'flex-start' : 'center'}
                                    alignItems="center"
                                    spacing={3}
                                >
                                    {avatarImage && (
                                        <Grid item>
                                            <Avatar src={avatarImage.childImageSharp.fixed.src} className={classes.avatarImage} />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Typography variant="h1" component="div">
                                            {title}
                                        </Typography>
                                        <Typography variant="subtitle2" component="p" className={classes.description}>
                                            {description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {!isScreenSizeMd && (
                                    <Grid item xs={12}>
                                        <Grid container wrap="nowrap" direction="row-reverse" alignItems="center">
                                            {illustrationImage && (
                                                <Grid item className={classes.illustrationWrapper}>
                                                    <GatsbyImage
                                                        image={illustrationImage.childImageSharp.gatsbyImageData}
                                                        alt={title}
                                                        loading="eager"
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                )}
            </PageSection>
            {children.length > 1 &&
                children.map((child, index) => {
                    let hasNoMargin = false;
                    let childProps = child?.props;
                    if (child?.props?.noMargin) {
                        hasNoMargin = true;
                        if (child?.type) {
                            const { noMargin, ...restChildProps } = childProps;
                            childProps = restChildProps;
                        }
                    }
                    return (
                        <PageSection
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={clsx(classes.pageContentWrapper, {
                                [classes.noMargin]: hasNoMargin,
                            })}
                        >
                            {child?.type && child?.props ? React.createElement(child.type, childProps) : child}
                        </PageSection>
                    );
                })}
            {(children.length === 1 || children.length === undefined) && (
                <PageSection className={classes.pageContentWrapper}>{children}</PageSection>
            )}
        </PageWrapper>
    );
};

BigContentHeaderLayout.defaultProps = {
    showIllustrationImageOnly: false,
    illustrationImage: undefined,
    metaImageSrc: undefined,
    avatarImage: undefined,
    description: undefined,
    content: undefined,
};

BigContentHeaderLayout.propTypes = {
    children: PropTypes.node.isRequired,
    pathname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    illustrationImage: PropTypes.oneOfType([PropTypes.object]),
    metaImageSrc: PropTypes.string,
    showIllustrationImageOnly: PropTypes.bool,
    avatarImage: PropTypes.oneOfType([PropTypes.object]),
    content: PropTypes.node,
};

export default BigContentHeaderLayout;
